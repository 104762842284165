<template lang="html">
  <transition mode="out-in">
    <span v-if="loading">Loading...</span>
    <div v-else class="ecology-waterProtection">
      <EcologyInputGrid>
        <DlgGridHeader>
          <IxRes>dlgCert.ecology.waterProtection.headers.technicalActions</IxRes>
        </DlgGridHeader>
        <EcologyInputLine
          v-for="name in ['undergroundFertilization', 'dragHose']" :key="name"
          :data="data" :name="name"
          show-percent-of="cultivated"
          @input="update"
        />
        <DlgGridCheckboxLine
          v-for="name in ['precisionFarming', 'lossReducing']" :key="name"
          :data="data" :name="name"
          @input="update"
        />
        <DlgGridHeader>
          <IxRes>dlgCert.ecology.waterProtection.headers.agriculturalActions</IxRes>
        </DlgGridHeader>
        <BsRadioList
          v-model="ProtectedRiverbanks" class="riverbanks"
          :options="['low','medium','high','veryHigh']"
          horizontal
        >
          <template #default="{option}">
            <IxRes>dlgCert.ecology.WaterForm.ProtectedRiverbanks_{{ option }}</IxRes>
          </template>

          <template #label>
            <IxRes>dlgCert.ecology.WaterForm.ProtectedRiverbanks</IxRes>
          </template>
        </BsRadioList>
      </EcologyInputGrid>
    </div>
  </transition>
</template>

<script>
import EcologyInputGrid from './elements/EcologyInputGrid'
import EcologyInputLine from './elements/EcologyInputLine'
import DlgGridHeader from './elements/DlgGridHeader'
import DlgGridCheckboxLine from './elements/DlgGridCheckboxLine'

import BsRadioList from '@components/bootstrap/BsRadioList'
import FormPartMixin from '@components/forms/FormPartMixin'
import StandardRulesMixin from '@components/forms/StandardRulesMixin'
import ApiMixin from '@dlg-cert/mixins/ApiMixin'

export default {
  components: {
    EcologyInputGrid,
    EcologyInputLine,
    DlgGridHeader,
    DlgGridCheckboxLine,
    BsRadioList
  },
  mixins: [
    FormPartMixin,
    StandardRulesMixin,
    ApiMixin
  ],
  computed: {
    apiUrl () {
      return `/api/v2/dlg-cert/${this.$route.params.orgUnitId}/ecology/${this.$route.params.year}/water-protection`
    },
    ProtectedRiverbanks: {
      get () {
        return this.data.degreeOfProtectedRiverbanks
      },
      set (value) {
        this.update({name: 'degreeOfProtectedRiverbanks', value: value})
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.v-enter, .v-leave-to {
  opacity: 0;
}
.v-enter-active, .v-leave-active {
  transition: opacity 0.3s;
}
.riverbanks {
  padding: 0.125em 0.5em;
}
</style>
